import { signin, signinWithGoogle, useAppDispatch } from "@/common/store";
import { Button, Divider, Form, Input } from "antd";
import Link from "next/link";
import Card from "@/components/Card";
import { Role } from "@/common/roles";
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useMetricsContext } from "@/common/metrics";
import { Logo } from "@/components/TopBar";
import { useLoading } from "@/components/util";

function Login() {
    const metrics = useMetricsContext();
    const dispatch = useAppDispatch();

    const { loading, callback: onFinish } = useLoading(
        async ({ username, password }) => {
            await dispatch(signin({ username, password }));
            metrics.log({ name: "login", login_method: "password" });
        },
        [metrics]
    );

    const onSigninWithGoogle = async (credentialResponse: CredentialResponse) => {
        await dispatch(signinWithGoogle({ token: credentialResponse.credential }));
        metrics.log({ name: "login", login_method: "google" });
    };

    return (
        <Card className="max-w-lg p-6 sm:p-12 self-center mt-5 sm:mt-10 mx-auto">
            <div className="text-center">
                <Logo dark />
            </div>
            <div
                style={{
                    backgroundColor: "#fff3cd",
                    color: "#856404",
                    border: "1px solid #ffeeba",
                    padding: "10px",
                    borderRadius: "4px",
                    marginBottom: "10px",
                    marginTop: "10px"
                }}
            >
                Note: We upgraded to a password-based sign in. If you are a returning user, please click “forgot
                password” and follow the steps.
            </div>
            <div className="flex justify-center my-8">
                <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
                    <GoogleLogin text={"signin_with"} onSuccess={onSigninWithGoogle} />
                </GoogleOAuthProvider>
            </div>
            <div>
                <Divider plain>Or</Divider>
            </div>
            <Form onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="username"
                    label="E-mail Address"
                    rules={[{ required: true, message: "Please input a valid email or username" }]}
                >
                    <Input placeholder="example@email.com" />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: "Please input a valid password" }]}
                >
                    <Input type="password" placeholder="Password" />
                </Form.Item>
                <div className="flex">
                    <div>
                        <Link href="/forgotpassword">Forgot password?</Link>
                    </div>
                </div>
                <Form.Item noStyle>
                    <Button size="large" className="w-full" type="primary" htmlType="submit" loading={loading}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
            <div className="text-center mt-10">
                Don't have an account yet?{" "}
                <Link href="/signup">
                    <b>
                        <a href="#">Sign up</a>
                    </b>
                </Link>
            </div>
        </Card>
    );
}

Login.role = Role.Visitor;
Login.destination = "/";

export default Login;
